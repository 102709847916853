import { CloseCircleOutlined } from "@ant-design/icons";
import { Col, Empty, Input, Row, Select, Spin, Switch, message } from "antd";
import CommonButton from "components/ButtonWidgets/CommonButton/CommonButton";
import React, { useState } from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { apiSaveRegistrationGeneral, apiSaveRegistrationMain } from "services/dashboardService";
import { isEmpty } from "utils/GlobalFunctions";
const RegistrationTab = (props) => {
  const { TextArea } = Input;
  const { registrationID, setRegistrationID, settingInfo } =
    props;
  const userDataStore = useSelector((x) => x.userDataStore);
  const appDataStore = useSelector((x) => x.appDataStore);

  const [registrationList, setRegistrationList] = useState([
    {
      title: "",
      description: "",
      registration_fee: "",
      sibling_discount: true,
      sibling_discount_amount: "",
      verify_zipcode: true,
      zipcodes_allowed: "",
      visible: 1
    }
  ]);
  const [errorField, setErrorField] = useState([]);
  const [isBusy, setIsBusy] = useState(false);

  useEffect(() => {
    if (settingInfo != undefined) {
      let tmpList = [...settingInfo.registration_main_list];
      if (tmpList.length > 0) {
        tmpList = tmpList.map((x) => {
          return {
            ID: x.ID,
            visible: 1,
            title: x.registration_title,
            description: x.registration_text,
            registration_fee: x.registration_fee,
            sibling_discount: x.sibling_discount == 1 ? true : false,
            sibling_discount_amount: x.sibling_discount_amount,
            verify_zipcode: x.verify_zipcode == 1 ? true : false,
            zipcodes_allowed: x.zipcodes_allowed,
            zip_discount_amount: x.zip_discount_amount
          };
        });

        setRegistrationList(tmpList);
      }
    }
  }, [settingInfo]);
  const onAddRegItem = () => {
    let tmpList = [...registrationList];
    let oneCode = {
      title: "",
      description: "",
      registration_fee: "",
      sibling_discount: true,
      sibling_discount_amount: "",
      verify_zipcode: true,
      zipcodes_allowed: "",
      zip_discount_amount: "",
      visible: 1
    };
    tmpList = [...tmpList, oneCode];
    setRegistrationList(tmpList);
  };

  const validateFields = () => {
    let errorList = Array();
    let tmpList = [...registrationList];
    tmpList.map((x, index) => {
      errorList = isEmpty(x, "title", errorList, index);
      errorList = isEmpty(x, "description", errorList, index);
      errorList = isEmpty(x, "registration_fee", errorList, index);
      if (x.sibling_discount) {
        errorList = isEmpty(x, "sibling_discount_amount", errorList, index);
      }
      if (x.verify_zipcode) {
        errorList = isEmpty(x, "zipcodes_allowed", errorList, index);
        errorList = isEmpty(x, "zip_discount_amount", errorList, index);
      }
    });
    setErrorField([...errorList]);
    return errorList.length == 0;
  };
  const onSave = () => {
    if (!validateFields()) return;

    const params = new URLSearchParams();
    params.append("token", userDataStore.token);
    params.append("registration_id", registrationID);
    params.append("registration_main_list", JSON.stringify(registrationList));
    setIsBusy(true);
    apiSaveRegistrationMain(params)
      .then((res) => {
        setIsBusy(false);
        if (
          res != undefined &&
          res.registration_main_list != undefined &&
          res.registration_id != undefined
        ) {
          setRegistrationID(res.registration_id);
          setRegistrationList([]);
          let tmpList = [...res.registration_main_list];
          tmpList = tmpList.map((x) => {
            return {
              ID: x.ID,
              visible: 1,
              title: x.registration_title,
              description: x.registration_text,
              registration_fee: x.registration_fee,
              sibling_discount: x.sibling_discount == 1 ? true : false,
              sibling_discount_amount: x.sibling_discount_amount,
              verify_zipcode: parseInt(x.verify_zipcode) == 1 ? true : false,
              zipcodes_allowed: x.zipcodes_allowed,
              zip_discount_amount: x.zip_discount_amount
            };
          });
          setRegistrationList(tmpList);
          message.success("Registration saved successfully.");
        } else {
          message.error(res?.msg);
        }
      })
      .catch((err) => {
        setIsBusy(false);
        message.error(err);
      });
  };
  return (
    <div>
      <Spin spinning={isBusy}>
        <Row gutter={[16, 16]} style={{ marginTop: 20 }} justify={"space-between"}>
          <Col>
            <div style={{ fontWeight: 600 }}>Registration</div>
          </Col>
          <Col>
            <CommonButton
              onClick={() => {
                onAddRegItem();
              }}
            >
              Add New Registration
            </CommonButton>
          </Col>
        </Row>
        <div>
          {registrationList.length > 0 ? (
            <div style={{ marginTop: 20 }}>
              {registrationList.map((x, index) => {
                if (x.visible == 1) {
                  return (
                    <div key={index} style={{ border: "1px solid #eee", padding: 10, marginTop: 10 }}>
                      <Row gutter={[16, 16]}>
                        <Col xs={24} sm={24}>
                          <Row style={{ paddingBottom: "5px", fontWeight: 600 }} justify={"space-between"}>
                            <Col>
                              <div>
                                Registration Title*
                              </div>
                            </Col>
                            <Col>
                              {index != 0 && <CloseCircleOutlined
                                style={{
                                  color: "red",
                                  marginBottom: 15,
                                  cursor: "pointer",
                                  fontSize: 16,
                                }}
                                onClick={() => {
                                  let tmpList = [...registrationList];
                                  tmpList[index].visible = 0;
                                  setRegistrationList(tmpList);
                                }}
                              />}
                            </Col>
                          </Row>
                          <Input
                            value={x.title}
                            className={
                              errorField !== undefined && errorField.includes(index + "title")
                                ? `common-input-error`
                                : `common-input`
                            }
                            onChange={(e) => {
                              if (errorField.includes(index + "title")) {
                                let errors = errorField.filter((y) => y != index + "title");
                                setErrorField([...errors]);
                              }
                              let tmpList = [...registrationList];
                              tmpList[index].title = e.target.value;
                              setRegistrationList(tmpList);
                            }}
                          />
                        </Col>
                      </Row>
                      <div style={{ marginTop: 10, fontWeight: 600, paddingBottom: 5 }}>
                        Registration Description*
                      </div>
                      <div>
                        <TextArea
                          rows={6}
                          value={x.description}
                          className={
                            errorField !== undefined && errorField.includes(index + "description")
                              ? `common-input-error`
                              : `common-input`
                          }
                          onChange={(e) => {
                            if (errorField.includes(index + "description")) {
                              let errors = errorField.filter((y) => y != index + "description");
                              setErrorField([...errors]);
                            }
                            let tmpList = [...registrationList];
                            tmpList[index].description = e.target.value;
                            setRegistrationList(tmpList);
                          }}
                        />
                      </div>
                      <Row gutter={16} justify={"space-between"} style={{ marginTop: 10 }}>
                        <Col>
                          <div style={{ paddingBottom: "5px", fontWeight: 600 }}>
                            Registration Fee
                          </div>
                          <Input
                            value={x.registration_fee}
                            type={"number"}
                            prefix="$"
                            suffix="USD"
                            className={
                              errorField !== undefined &&
                                errorField.includes(index + "registration_fee")
                                ? `common-input-error`
                                : `common-input`
                            }
                            onChange={(e) => {
                              if (errorField.includes(index + "registration_fee")) {
                                let errors = errorField.filter(
                                  (y) => y != index + "registration_fee"
                                );
                                setErrorField([...errors]);
                              }
                              let tmpList = [...registrationList];
                              tmpList[index].registration_fee = e.target.value;
                              setRegistrationList(tmpList);
                            }}
                          />
                        </Col>
                        <Col>
                          <Row gutter={[32, 16]}>
                            <Col>
                              <div style={{ paddingBottom: "5px", fontWeight: 600 }}>
                                Sibling Discount
                              </div>
                              <Switch
                                checkedChildren="Yes"
                                unCheckedChildren="No"
                                checked={x.sibling_discount}
                                style={{ marginBottom: 10 }}
                                onChange={(e) => {
                                  let tmpList = [...registrationList];
                                  tmpList[index].sibling_discount = e;
                                  setRegistrationList(tmpList);
                                }}
                              />
                            </Col>
                            <Col>
                              <div style={{ paddingBottom: "5px", fontWeight: 600 }}>
                                Sibling Discount Amount
                              </div>
                              <Input
                                value={x.sibling_discount_amount}
                                type={"number"}
                                prefix="$"
                                suffix="USD"
                                className={
                                  errorField !== undefined &&
                                    errorField.includes(index + "sibling_discount_amount")
                                    ? `common-input-error`
                                    : `common-input`
                                }
                                onChange={(e) => {
                                  if (errorField.includes(index + "sibling_discount_amount")) {
                                    let errors = errorField.filter(
                                      (y) => y != index + "sibling_discount_amount"
                                    );
                                    setErrorField([...errors]);
                                  }
                                  let tmpList = [...registrationList];
                                  tmpList[index].sibling_discount_amount = e.target.value;
                                  setRegistrationList(tmpList);
                                }}
                              />
                            </Col>
                          </Row>
                          <Row gutter={[32, 16]} style={{ marginTop: 10 }}>
                            <Col>
                              <div style={{ paddingBottom: "5px", fontWeight: 600 }}>
                                Verify Zip Code
                              </div>
                              <Switch
                                checkedChildren="Yes"
                                unCheckedChildren="No"
                                checked={x.verify_zipcode}
                                style={{ marginBottom: 10 }}
                                onChange={(e) => {
                                  let tmpList = [...registrationList];
                                  tmpList[index].verify_zipcode = e;
                                  setRegistrationList(tmpList);
                                }}
                              />
                            </Col>
                            <Col>
                              <div style={{ paddingBottom: "5px", fontWeight: 600 }}>
                                Zip Codes Allowed (seperate with a comma)
                              </div>
                              <Input
                                value={x.zipcodes_allowed}
                                className={
                                  errorField !== undefined &&
                                    errorField.includes(index + "zipcodes_allowed")
                                    ? `common-input-error`
                                    : `common-input`
                                }
                                onChange={(e) => {
                                  if (errorField.includes(index + "zipcodes_allowed")) {
                                    let errors = errorField.filter(
                                      (y) => y != index + "zipcodes_allowed"
                                    );
                                    setErrorField([...errors]);
                                  }
                                  let tmpList = [...registrationList];
                                  tmpList[index].zipcodes_allowed = e.target.value;
                                  setRegistrationList(tmpList);
                                }}
                              />
                            </Col>
                            <Col>
                              <div style={{ paddingBottom: "5px", fontWeight: 600 }}>
                                Zip Discount Amount
                              </div>
                              <Input
                                value={x.zip_discount_amount}
                                type={"number"}
                                prefix="$"
                                suffix="USD"
                                className={
                                  errorField !== undefined &&
                                    errorField.includes(index + "zip_discount_amount")
                                    ? `common-input-error`
                                    : `common-input`
                                }
                                onChange={(e) => {
                                  if (errorField.includes(index + "zip_discount_amount")) {
                                    let errors = errorField.filter(
                                      (y) => y != index + "zip_discount_amount"
                                    );
                                    setErrorField([...errors]);
                                  }
                                  let tmpList = [...registrationList];
                                  tmpList[index].zip_discount_amount = e.target.value;
                                  setRegistrationList(tmpList);
                                }}
                              />
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </div>
                  );
                }
              })}
            </div>
          ) : (
            <></>
          )}
        </div>
        <div style={{ marginTop: 60, textAlign: "center" }}>
          <CommonButton
            style={{ padding: "12px 45px" }}
            onClick={() => {
              onSave();
            }}
          >
            Save
          </CommonButton>
        </div>
      </Spin>
    </div>
  );
};
export default RegistrationTab;
